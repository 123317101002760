<template>
  <div id="subject">
    <defaultTemplate v-loading="loading">
      <div v-if="getSubjectdata">
        <div class="pd-x-5 pd-t-5 is-flex js-between ai-center">
          <div class="s-width">
            <p class="font-20 font-weight-bold">My Courses</p>
          </div>
          <div class="is-flex ai-center">
            <el-input
              class="mg-r-7"
              v-if="Search"
              placeholder="Search"
              v-model="searchText"
              @keyup.13.native="searchSubject"
            ></el-input>
            <i
              class="font-14 font-weight-bold color-green-light el-icon-search"
              @click="Search = !Search"
            ></i>
          </div>
        </div>
        <div class="mg-x-5 mg-b-2" v-if="fillFavorite.length > 0">
          <p class="font-18 font-weight-500 border-b pd-b-7">
            <i class="color-star-light fas fa-star mg-r-6"></i>Favorite Course
            ({{ fillFavorite.length }})
          </p>
        </div>
        <div v-else></div>

        <div v-if="getSubjectdata.length > 0">
          <div v-for="(item, index) in getSubjectdata" :key="'a' + index">
            <div class="mg-x-5 mg-b-2" v-if="item.favorite == true">
              <CardProgress
                @changeFavorite="delFavorite"
                :nowHour="item.obtainedLearningHour"
                :totalHour="item.badgeLearningHour"
                :percentNow="
                  percentHour(item.obtainedLearningHour, item.badgeLearningHour)
                "
                :bgProgress="`green`"
                :nameSubject="item.courseName"
                :favorite="1"
                :courseId="item.courseId"
                :subjectId="item.id"
              />
            </div>
          </div>
          <div class="pd-x-5 mg-b-2" v-if="fillSubject.length > 0">
            <p class="font-18 font-weight-500 border-b pd-b-7">
              Course ({{ fillSubject.length }})
            </p>
          </div>
          <div v-else></div>
          <div v-for="(item, index) in getSubjectdata" :key="index">
            <div class="mg-x-5 mg-b-2" v-if="item.favorite != true">
              <CardProgress
                @changeFavorite="addFavorite"
                :nowHour="item.obtainedLearningHour"
                :totalHour="item.badgeLearningHour"
                :percentNow="
                  percentHour(item.obtainedLearningHour, item.badgeLearningHour)
                "
                :bgProgress="`green`"
                :nameSubject="item.courseName"
                :favorite="0"
                :courseId="item.courseId"
                :subjectId="item.id"
              />
            </div>
          </div>
        </div>
        <div v-else class="text-center mg-t-2">
          <p class="font-22 font-weight-500">ไม่พบข้อมูล Course</p>
          <router-link :to="`/calendar`">
            <p class="font-14 mg-t-5">คลิกเพื่อลงทะเบียน</p>
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="pd-x-5 pd-t-5">
          <div class="s-width">
            <p class="font-20 font-weight-bold">My Courses</p>
          </div>

          <div class="text-center">
            <p class="font-22 font-weight-500">ไม่พบข้อมูล Course</p>
            <router-link :to="`/calendar`">
              <p class="font-14 mg-t-5">คลิกเพื่อลงทะเบียน</p>
            </router-link>
          </div>
        </div>
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import CardProgress from "@/components/CardProgress.vue";
import { HTTP } from "@/service/axios";
export default {
  name: "Subject",
  mounted() {
    this.getSubjectLearning();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  components: {
    defaultTemplate,
    CardProgress
  },
  data() {
    return {
      loading: false,
      searchText: "",
      Search: false,
      fillFavorite: "",
      fillSubject: "",
      getSubjectdata: null,
      getSubjectdataCount: null
    };
  },

  methods: {
    getSubjectLearning() {
      this.loading = true;
      console.log(" Get Subject");
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`user/subject`)
        .then(res => {
          console.log(res, "res res");
          if (res.data.status == 200) {
            this.getSubjectdata = res.data.obj.subject;
            this.getSubjectdataCount = res.data.obj.subject;
            this.getFavorite();
            this.getSubject();
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    percentHour(nowHour, totalHour) {
      //หน้า * 100 หารหลัง
      if (typeof nowHour == "undefined" || typeof totalHour == "undefined") {
        console.log("ssssssssssss");
        return 0;
      }
      if (totalHour != 0) {
        let percentNow = (nowHour * 100) / totalHour;
        return Number(percentNow.toFixed(0));
      }
      return 0;
    },
    addFavorite(id) {
      let addFav = {
        subjectId: id,
        favorite: true
      };

      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`save/favorite`, addFav)

        .then(res => {
          if (res.data.success) {
            this.getSubjectLearning();
            this.getFavorite();
            this.getSubject();
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
    delFavorite(id) {
      let delFav = {
        subjectId: id,
        favorite: false
      };

      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`save/favorite`, delFav)
        .then(res => {
          if (res.data.success) {
            this.getSubjectLearning();
            this.getFavorite();
            this.getSubject();
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
    getFavorite() {
      this.fillFavorite = this.getSubjectdata.filter(
        item => item.favorite == true
      );
    },
    getSubject() {
      this.fillSubject = this.getSubjectdata.filter(
        item => item.favorite == false
      );
    },

    searchSubject() {
      if (this.searchText != "") {
        // this.getSubjectLearning();

        console.log(this.searchText, "คำค้นหา");
        this.getSubjectdata = this.getSubjectdataCount.filter(
          fil =>
            fil.courseId.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1 ||
            fil.courseName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1
        );
        this.getFavorite();
        this.getSubject();
      } else {
        this.getSubjectLearning();
      }
    }
  }
};
</script>

<style></style>
